import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import * as pdfjs from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import "./DownloadPage.css";

const SecurePdfViewer = () => {
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [isScreenBlocked, setIsScreenBlocked] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const pdfContainerRef = useRef(null);
  const pdfInstance = useRef(null);
  const scaleRef = useRef(1.0);

  useEffect(() => {
    const fetchPdf = async () => {
      const encryptedLink = searchParams.get("pdf");
      const password = searchParams.get("password");

      if (!encryptedLink || !password) {
        setErrorMessage("Missing required parameters in the URL.");
        return;
      }

      try {
        const response = await fetch(
          `/api/view-pdf?pdf=${encodeURIComponent(encryptedLink)}&password=${encodeURIComponent(password)}`
        );

        if (response.ok) {
          const blob = await response.blob();
          renderPdf(blob);
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.error || "Failed to load the PDF.");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        setErrorMessage("An error occurred while fetching the PDF.");
      }
    };

    fetchPdf();
    setupSecurityFeatures();
  }, [searchParams]);

  const setupSecurityFeatures = () => {
    // Disable right-click
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    // Disable DevTools & Print Screen
    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey && ["p", "s", "u", "i", "j", "h"].includes(e.key.toLowerCase())) {
        e.preventDefault();
        alert("Action not allowed!");
      }
      if (e.key === "PrintScreen") {
        navigator.clipboard.writeText(""); // Clears clipboard
        triggerScreenshotWarning();
      }
    });

    // Detect app losing focus (Quick Settings / Notification dropdown)
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        console.log("App lost focus - Black overlay activated!");
        triggerBlackScreenOverlay();
      }
    });

    // Detect if Power + Volume Down is pressed (Limited Browser Support)
    window.addEventListener("blur", () => {
      console.log("Possible screenshot attempt detected!");
      triggerBlackScreenOverlay();
    });

    return () => {
      document.removeEventListener("visibilitychange", triggerBlackScreenOverlay);
      window.removeEventListener("blur", triggerBlackScreenOverlay);
    };
  };

  const triggerScreenshotWarning = () => {
    setShowWarning(true);
    setTimeout(() => setShowWarning(false), 3000);
  };

  const triggerBlackScreenOverlay = () => {
    setIsScreenBlocked(true);
    setTimeout(() => setIsScreenBlocked(false), 10000);
  };

  const renderPdf = async (blob) => {
    const pdfData = await blob.arrayBuffer();
    const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
    pdfInstance.current = pdf;
    adjustAndRenderPages(pdf);
  };

  const adjustAndRenderPages = useCallback(async (pdf) => {
    const container = pdfContainerRef.current;
    if (!container) return;
    container.innerHTML = "";

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const firstPage = await pdf.getPage(1);
    const viewport = firstPage.getViewport({ scale: 1.0 });

    const widthScale = containerWidth / viewport.width;
    const heightScale = containerHeight / viewport.height;
    const scaleFactor = Math.min(widthScale, heightScale);

    scaleRef.current = scaleFactor;

    for (let i = 1; i <= pdf.numPages; i++) {
      const canvas = document.createElement("canvas");
      container.appendChild(canvas);

      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: scaleRef.current });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      }).promise;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (pdfInstance.current) {
        adjustAndRenderPages(pdfInstance.current);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [adjustAndRenderPages]);

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  return (
    <div className="pdf-viewer-container">
      {isScreenBlocked && <div className="screen-blocker" style={{ backgroundColor: "black" }} />}
      {showWarning && (
        <div className="screenshot-warning">
          <p>No screenshots allowed!</p>
        </div>
      )}
      <div className="pdf-container no-copy" ref={pdfContainerRef} />
    </div>
  );
};

export default SecurePdfViewer;


