import React, { useState, useEffect } from 'react';
import './DownloadPage.css';

const DownloadPage = () => {
  const [email, setEmail] = useState('');
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const storedEmails = JSON.parse(localStorage.getItem('verifiedEmails')) || [];
    setVerifiedEmails(storedEmails);

    const links = JSON.parse(sessionStorage.getItem('downloadLinks'));
    const expirationTime = sessionStorage.getItem('downloadLinksExpiration');

    if (links && expirationTime) {
      const currentTime = new Date().getTime();
      const expTime = parseInt(expirationTime, 10);

      if (currentTime > expTime) {
        sessionStorage.removeItem('downloadLinks');
        sessionStorage.removeItem('downloadLinksExpiration');
        window.location.href = '/ebooks';
      } else {
        setDownloadLinks(links);
      }
    } else {
      window.location.href = '/ebooks';
    }
  }, []);
const handleSendEmail = async () => {
  if (!email) {
    alert('Please enter an email address.');
    return;
  }

  const linksToSend = downloadLinks.map(item => item.link.url || item.link);

  try {
    const response = await fetch('/api/send-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, links: linksToSend }),
    });

    if (response.ok) {
      setEmailSent(true);
      alert(`Download link sent to ${email}. The PDF is password-protected, and the password has been sent to your email.`);
      setEmail('');
      window.location.href = '/ebooks';
    } else {
      const errorData = await response.json();
      alert(`Error: ${errorData.error || 'Failed to send email. Please try again.'}`);
    }
  } catch (error) {
    console.error('Error sending email:', error);
    alert(`There was an error sending your email. Please check your connection and try again. Error details: ${error.message}`);
  }
};

  return (
    <div className="download-page">
      <h2 className="title">Thank You for Your Purchase!</h2>

      {!emailSent ? (
        <div className="email-section">
          <p>Enter your email address to receive your ebook</p>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <button onClick={handleSendEmail} className="send-btn">Send Link</button>
        </div>
      ) : (
        <p className="success-message">Successfully sent. The book is password-protected. Please check your email for the password!</p>
      )}

      {downloadLinks.length === 0 ? (
        <div className="no-links-section">
          <p>No download links available.</p>
          <button onClick={() => window.location.href = '/ebooks'} className="back-btn">
            Go to Books
          </button>
        </div>
      ) : (
        <ul className="links-list">
          {downloadLinks.map((item, index) => (
            <li key={index} className="link-item">
              {/* Display the link to the restricted book here */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DownloadPage;
